import React, { useEffect } from "react";

import styled from "styled-components";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, FormControlLabel, Grid, Menu, MenuItem } from "@mui/material";

import { MoreHoriz } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { usePallet } from "../../../../contexts/PalletContext";
import { useAlert } from "../../../../contexts/AlertContext";

import RelatorioProposicaoModal from "../relatorio_proposicao/RelatorioProposicaoModal";

import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import ButtonNaoSeguir from "../../../../components/ButtonNaoSeguir";
import ButtonSeguir from "../../../../components/ButtonSeguir";

import { IProposicao } from "../../../../models/Proposicao.Model";

import ProposicaoService from "../../../../services/ProposicaoService";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoritosService from "../../../../services/FavoritosService";

const ChipStyle = styled(Grid)`
  background-color: ${(props) => props.pallet.backGroundPageColorSecondary};
  text-transform: uppercase;
  border-radius: 100px;
  font-size: 12px;
  border: 1px solid ${(props) => props.pallet.general.color4};
  color: ${(props) => props.pallet.general.color4};
  padding: 4px 11px 4px 11px;
  /* margin-bottom: 7px; */
  line-height: 15px;
  letter-spacing: 1px;
`;

const DotStyle = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 9px;
  margin-top: 3px;
`;

const ButtonMenu = styled(Button)`
  background-color: ${(props) => props.pallet.backgroundColorTertiary};
  font-family: ${(props) => props.pallet.fontFamily.general};
  font-weight: ${(props) => props.pallet.fontWeight.lighter};
  font-size: ${(props) => props.pallet.fontSize.titleWidgets};
  color: ${(props) => props.pallet.general.color4};
  border-radius: 6px;
  margin-left: 10px;
  width: 25px;
  height: 45px;
  z-index: 10;

  &:hover {
    background: white;
    filter: contrast(0.5);
  }
`;

const MenuItemStyle = styled(MenuItem)`
  font-family: ${(props) => props.pallet.fontFamily.general};
  font-weight: ${(props) => props.pallet.fontWeight.button};
  font-size: ${(props) => props.pallet.general.size14};
  color: ${(props) => props.pallet.color.tertiary.font};
  border-radius: 6px;
  padding: 7px;
  text-transform: none;
`;

export const ProposicaoConsultaBotoes = (props: {
    proposicao: IProposicao;
    onClick?: () => void;
    permissaoUsuario: number;
}) => {
    const navigate = useNavigate();
    const { pallet } = usePallet();

    const [seguindo, setSeguindo] = React.useState(props.proposicao.cliente.seguindo);
    const [favoritado, setFavoritado] = React.useState<boolean>(false);
    const [idFavorito, setIdFavorito] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [pathOn] = React.useState(window.location.href.split('br/')[1]);
    const { NewAlert } = useAlert();

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    useEffect(() => {
        FavoritosService.getFavoritoUnico(props.proposicao.id, 'proposicao').then((res) => {
            if (res) {
                setIdFavorito(res.id)
                setFavoritado(true)

            } else {
                setFavoritado(false)
            }
        });
    }, []);


    const handleChangeFavorito = async (event: any, id: number, modulo: string,) => {
        const data = {
            modulo: modulo,
            id_modulo: id,
            tipo: 'PROPOSIÇÕES'
        };



        FavoritosService.getFavoritoUnico(props.proposicao.id, 'proposicao').then((res) => {
            if (res.id) {
                setFavoritado(false)
                FavoritosService.Delete(id, res.id, false, 'PROPOSIÇÕES').then((res) => {
                    if (res) {
                        NewAlert("success", "O item foi removido dos favoritos com sucesso.");
                    } else {
                        NewAlert(
                            "error",
                            "Erro ao Deletar, tente novamente."
                        );
                    }
                });

            } else {

                FavoritosService.Create(data).then((res) => {
                    if (res) {
                        NewAlert("success", "O item foi adicionado aos favoritos com sucesso.");
                    }
                    else {
                        NewAlert("error", "Erro ao favoritar, verifique os campos e salve novamente.");
                    }

                });


            }
        });





    }

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        };
    };

    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com Emenda Saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com Emenda Saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const seguirClick = (id: number, seguir: boolean) => {
        ProposicaoService.SetSeguir(id, seguir, pathOn).then((res) => {
            if (res) {
                setSeguindo(seguir);
                NewAlert("success", "Registro Salvo com sucesso");
            } else {
                NewAlert("error", "Ocorreu um erro");
            };
        });
    };

    const deletarProposicao = async (id) => {
        await ProposicaoService.Delete(id).then((res) => {
            if (res) {
                handleClose();
                navigate("/proposicao");
                NewAlert("success", "Proposicao apagado com sucesso");
            } else {
                NewAlert("error", "Erro ao apagar, verifique os campos e apague novamente");
            };
        }).then((x) => props?.onClick);
    };

    return (
        <Grid
            container
            item
            style={{ display: "flex", justifyContent: "space-between " }}
        >
            {props.permissaoUsuario != 4 ? (
                <Grid
                    style={{
                        display: "flex",
                        justifyContent: "space-between ",
                        marginTop: "15px",
                        marginLeft: `${isMobile ? "0px" : "50px"}`,
                    }}
                >
                    <Grid style={{ marginRight: "14px" }}>
                        <ChipStyle pallet={pallet}>
                            <Grid
                                style={{ display: "flex", justifyContent: "space-between " }}
                            >
                                <DotStyle
                                    bg={posicionamentoColor(
                                        props.proposicao?.cliente?.posicionamento_descricao
                                    )}
                                ></DotStyle>
                                <Grid>
                                    POSICIONAMENTO{" "}
                                    {props.proposicao?.cliente?.posicionamento_descricao}
                                </Grid>
                            </Grid>
                        </ChipStyle>
                    </Grid>
                    <Grid>
                        <ChipStyle pallet={pallet}>
                            <Grid
                                style={{ display: "flex", justifyContent: "space-between " }}
                            >
                                <DotStyle
                                    bg={prioridadeColor(
                                        props.proposicao?.cliente?.prioridade_descricao
                                    )}
                                ></DotStyle>
                                <Grid>
                                    PRIORIDADE {props.proposicao?.cliente?.prioridade_descricao}
                                </Grid>
                            </Grid>
                        </ChipStyle>
                    </Grid>
                </Grid>
            ) : null}

            <Grid
                style={{
                    marginLeft: `${isMobile ? '4px' : "20px"}`,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: 'row' 
                    
                }}
            >
                {props.permissaoUsuario !== 4 ? (
                    <Grid>
                        <FormControlLabel
                            label={"Favorito"}
                            control={
                                <Checkbox

                                    // {...label}
                                    // icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                    icon={favoritado ? <StarIcon /> : <StarBorderIcon />}
                                    checkedIcon={<StarIcon />}
                                    defaultChecked={favoritado}
                                    value={favoritado}

                                    style={{ color: `${pallet.general.color2}` }}
                                    onChange={(e) => handleChangeFavorito(e, props.proposicao.id, 'proposicao')}
                                />
                            } />
                        <ButtonNaoSeguir
                            id={props.proposicao.id}
                            status={seguindo}
                            onClick={() => seguirClick(props.proposicao.id, false)}
                            width={"140px"}
                        />
                        {
                            isMobile ?

                        <div style={{marginLeft:'95px'}}>
                        <ButtonSeguir
                            id={props.proposicao.id}
                            status={seguindo}
                            onClick={() => seguirClick(props.proposicao.id, true)}
                            width={"110px"}
                        />
                        </div>

                        :
                        <ButtonSeguir
                        id={props.proposicao.id}
                        status={seguindo}
                        onClick={() => seguirClick(props.proposicao.id, true)}
                        width={"110px"}
                        />


                        }
                    </Grid>
                ) : null}

                {props.permissaoUsuario !== 4 ? (
                    <Grid
                        style={{
                            marginTop: isMobile ? null :"10px",
                            marginRight: `${isMobile ? null : "40px"}`,
                        }}
                    >
                        <>
                            <ButtonMenu
                                pallet={pallet}
                                id={`button-${props.proposicao.id}`}
                                aria-controls={
                                    anchorEl ? `menu-${props.proposicao.id}` : undefined
                                }
                                disableElevation
                                variant="contained"
                                size="medium"
                                onClick={(e) => handleClick(e, props.proposicao.id)}
                                endIcon={
                                    <Grid
                                        style={{
                                            fontSize: "30px",
                                            marginTop: "9px",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <MoreHoriz fontSize="inherit" />
                                    </Grid>
                                }
                            ></ButtonMenu>
                            <Menu
                                elevation={0}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                style={{
                                    border: "1px solid lightgrey",
                                    borderRadius: "6px",
                                }}
                                id={`menu-${props.proposicao.id}`}
                                MenuListProps={{
                                    "aria-labelledby": `button-${props.proposicao.id}`,
                                }}
                                anchorEl={anchorEl}
                                open={open === props.proposicao.id}
                                onClose={handleClose}
                            >
                                {props.permissaoUsuario !== 4 && !props.proposicao.integrada ? (
                                    <MenuItemStyle
                                        pallet={pallet}
                                        onClick={() =>
                                            props.permissaoUsuario !== 3
                                                ? navigate(
                                                    `/proposicao/${props.proposicao.id}/cadastro`
                                                )
                                                : NewAlert(
                                                    "error",
                                                    "Não é possível realizar está ação com este perfil."
                                                )
                                        }
                                    >
                                        <EditIcon
                                            style={{
                                                color: `${pallet.color.tertiary.font}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                marginRight: "16px",
                                                // width: "50px"
                                            }}
                                        />
                                        Editar
                                    </MenuItemStyle>
                                ) : null}
                                {!props.proposicao.integrada ? (
                                    <MenuItemStyle pallet={pallet}>
                                        <ButtonDialogConfirmation
                                            styles={{
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.button,
                                                fontSize: `${pallet.general.size14}`,
                                                color: `${pallet.color.tertiary.font}`,
                                                borderRadius: "6px",
                                                textTransform: "capitalize",
                                                padding: "unset",
                                            }}
                                            title={"CONFIRMAÇÃO"}
                                            confirmationEvent={() =>
                                                props.permissaoUsuario !== 3
                                                    ? deletarProposicao(props.proposicao.id)
                                                    : NewAlert(
                                                        "error",
                                                        "Não é possível realizar está ação com este perfil."
                                                    )
                                            }
                                            title_button={"Apagar"}
                                            confirmation_button={"Apagar"}
                                            content={"APAGAR PROPOSIÇÃO?"}
                                            message={"Esta ação não poderá ser desfeita."}
                                        >
                                            <DeleteIcon
                                                style={{
                                                    color: `${pallet.color.tertiary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    marginRight: "16px",
                                                }}
                                            />
                                            {/* Apagar */}
                                        </ButtonDialogConfirmation>
                                    </MenuItemStyle>
                                ) : null}
                                <MenuItemStyle pallet={pallet}>
                                    <RelatorioProposicaoModal
                                        proposicao={props.proposicao}
                                    />
                                </MenuItemStyle>
                            </Menu>
                        </>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

import { Button, FormControlLabel, FormGroup, Grid, Link, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AnyObject } from "yup/lib/types";
import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import Main from "../../../main/Main";
import filtersBackgroundImage from '../../../../../src/img/background_filters_dashboard.png';
import SummaryPanelWidget from "../../../workspace/_components/SummaryPanelWidget";
import TCService from "../../../../services/TCService";
import { IListInfo } from "../../../../models/IListInfo";
import { IFilterData } from "../../../../components/filter/ListFilter";
import ButtonIconSalvar from "../../../../components/ButtonIconSalvar";
import MenuButtonIconNoText from "../../../../components/MenuButtonIconNoText";
import { MoreHoriz } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RuleIcon from '@mui/icons-material/Rule';
import TCTemaCorporativoTemaEdicao from "../Tema/TCTemaCorporativo.Tema.Edicao";
import ComboBox from "../../../../components/ComboBox";
import { useAlert } from "../../../../contexts/AlertContext";
import TCTemasCorporativosTopicoList, { IListInfoTopico } from "../Topico/TCTemasCorporativosTopicoList";
import TCTemaCorporativoSubTemaEdicao from "../SubTema/TCTemaCorporativo.SubTema.Edicao";
import CircularLoading from "../../../../components/CircularLoading";

const HeaderPaper = styled(Paper)`
  background-size: cover;
  /* background-position: center; */
  justify-content: center;
  height: 80px;
  padding: 0 15px;
`;

const HeaderContentPaper = styled(Paper)`
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
`;

const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 9px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 8px 4px 8px;
    line-height: 15px;
    letter-spacing: 1px;
  `;

const HeaderText = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.titleWidgets};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;

const HeaderTextSub = styled(Typography)`
  font-weight: ${(props) => props.pallet.fontWeight.subtitle};
  font-size: ${(props) => props.pallet.fontSize.text_10};
  font-family: ${(props) => props.pallet.fontFamily.general};
  color: ${(props) => props.pallet.color.colorBase.greyText};
`;

const TitleText = styled(Typography)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const TitleTextSub = styled(Typography)`
  color: ${(props) => props.pallet.backgroundColorPrimary};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: 10px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 5px;
    width: 5px;
    margin-right: 9px;
    margin-top: 4px;
  `;


const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
	/* width: 10%; */
	width: 100%;
	margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
	margin-top: 30px;
`;

const ContainerCard = styled(Grid)`
	margin-top: 20px;

`;

const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 40px;
	justify-content: center;
`;

export default function TCTemasCorporativosTopicoLista() {
	const { pallet } = usePallet();
	const classes = useStyles();
	//const { idGrupoParm } = useParams() as any;
	const { idSubtemaParm } = useParams() as any;
	const navigate = useNavigate();

	const { NewAlert } = useAlert();


	//## Paginação
	const [infoList, setInfoList] = useState<IListInfo>({
		pageIndex: 1,
		pageSize: 8,
		pageItens: 0,
		totalItens: 0,
		totalPages: 0,
	});

	//## InfoTema
	const [infoTema, setInfoTema] = useState<IListInfoTopico>({
		id: 0,
		descricao: "",
		descricaoGrupo: "",
		idGrupo: 0,
		idTema: 0,
		descricaoTema: '',
		ativo: true,
		totalSubTemas: 0,
		totalSubTemasAtivos: 0,
		totalTopicos: 0,
		totalTopicosAtivos: 0,
		prioridade: 0
	});

	const prioridadeColor = (value: string) => {
		switch (value) {
			case "Máxima":
				return `${pallet.charts.red}`;
			case "Baixa":
				return `${pallet.charts.blue}`;
			case "Média":
				return `${pallet.charts.yellow}`;
			case "Alta":
				return `${pallet.charts.purple}`;
			case "Mínima":
				return `${pallet.charts.green}`;
			default:
				return `${pallet.charts.lightGrey}`;
		}
	};

	const prioridadeDescricao = (value: number) => {
		switch (value) {
			case 1:
				return "Mínima";
			case 2:
				return "Baixa";
			case 3:
				return "Média";
			case 4:
				return "Alta";
			case 5:
				return "Máxima";
			default:
				return "Não definido";
		}
	};

	const [currentPageSize, setCurrentPageSize] = useState<number>(50);
	const [currentPage, setCurrentPage] = useState<number>(1);
	// const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
	const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
	const [checked, setChecked] = React.useState<boolean>();
	const [lista, setLista] = useState([]);
	const [listaDescricao, setListaDescricao] = useState<string[]>([]);
	const [busca, setBusca] = useState<IFilterData[]>();
	const [descricaoParcial, setDescricaoParcial] = useState<string>("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (descricaoParcial && descricaoParcial.trim().length > 2) {
			TCService.getTopicos(idSubtemaParm, currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
				.then(async res => {
					if (res) {
						const data = [];

						res.data.map(item => data.push({
							key: item.subtema_id,
							label: item.subtema_descricao,
							value: item.subtema_id
						}))

						setListaDescricao(data.map(item => { return item.label }));
						setBusca(() => [...data]);

					}
				});
		} else if (descricaoParcial === "") {
			loadTopicosList();
		}
	}, [descricaoParcial]);

	const loadTopicosList = () => {
		TCService.getTopicos(idSubtemaParm, currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
			.then(async (res) => {
				if (res) {
					setLista(res.data);
					setInfoList(res.listInfo);
					setInfoTema(res.listInfoTema);
					setChecked(res.listInfoTema.ativo);
				}
			});
	};

	const handleOnChangeActive = (id_reg: number) => {
		setLoading(true);
		TCService
			.putSubTemaActive(id_reg, { data: { ativo: !checked } })
			.then((res) => {
				if (res) {
					NewAlert("success", "Registro alterado.");
					setChecked(!checked)
					loadTopicosList()
				} else {
					NewAlert("error", "Houve um erro. Tente novamente.");
				}
			});
		setTimeout(() => {
			setLoading(false);
		}, 100);
	};

	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						<Grid item xs={12} style={{}}>
							<HeaderPaper
								className={classes.paper}
								style={{
									backgroundImage: `url(${filtersBackgroundImage})`,
								}}
							/>
							<HeaderContentPaper
								className={classes.paper}
								style={{
									//textTransform: "uppercase",
									padding: '40px',
								}}
							>
								<Grid container justifyContent="space-between">
									<Grid>
										<Typography align="justify" style={{
											padding: "3px 9px",
											borderRadius: "13px",
											backgroundColor: pallet.color.colorBase.greyBackground,
											fontFamily: pallet.fontFamily.general,
											fontWeight: pallet.fontWeight.subtitle,
											fontSize: pallet.fontSize.text_10,
											color: pallet.color.colorBase.greyText,
											marginBottom: "15px"
										}}>
											{infoTema.ativo ? "ATIVO" : "INATIVO"}
										</Typography>
									</Grid>
									<Grid>
										<Grid container >
											<Grid item>
												<ButtonIconSalvar
													onClick={() => navigate('/classificacaodetemas/cadastrar/S')}
													tipo={"cadastrar_subtema"}
													backgroundColor={pallet.backgroundColorPrimary}
													border={"1px solid lightgrey"}
													fontColor={pallet.textColorPrimary}
													fontSize={pallet.general.size12}
													icon={""}
													title={"CADASTRAR"}
												/>
											</Grid>
											<Grid item style={{ marginLeft: "10px" }}>
												<PopupState variant="popover" popupId="demo-popup-menu">
													{(popupState) => (
														<React.Fragment>
															<Button
																{...bindTrigger(popupState)}
																disableElevation
																// onClick={openDatePicker}
																variant="contained"
																size="small"
																style={{
																	backgroundColor: `${pallet.color.secundary.background}`,
																	textTransform: 'capitalize',
																	fontFamily: `${pallet.fontFamily.general}`,
																	fontWeight: pallet.fontWeight.button,
																	fontSize: `${pallet.fontSize.smallButton}`,
																	color: `${pallet.color.secundary.font}`,
																	height: '42px'
																}}
															>
																<IconButton
																	{...bindTrigger(popupState)}
																>
																	<MoreHorizIcon />
																</IconButton>
															</Button>
															<Menu {...bindMenu(popupState)} style={{ marginTop: `${isMobile ? "18px" : "12px"}` }}>
																<MenuItem>
																	<TCTemaCorporativoSubTemaEdicao
																		idregister={idSubtemaParm}
																		reloadList={loadTopicosList}
																		closeMenu={popupState.close}
																	/>

																</MenuItem>
																<MenuItem>
																	<ListItemIcon>
																		<RuleIcon fontSize="small" />
																	</ListItemIcon>
																	<FormGroup>
																		<FormControlLabel control={
																			<Switch
																				checked={checked}
																				onChange={() =>
																					handleOnChangeActive(idSubtemaParm)
																				}
																				onClick={popupState.close}
																			/>
																		} label={
																			<ListItemText>
																				{checked ? "Ativo" : "Inativo"}
																			</ListItemText>
																		} />
																	</FormGroup>
																</MenuItem>
															</Menu>
														</React.Fragment>
													)}
												</PopupState>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<HeaderText pallet={pallet}>SUBTEMA</HeaderText>
								<Grid item xs={12} key="TitleText">
									<TitleText pallet={pallet}>{infoTema.descricao}</TitleText>
								</Grid>
								<Grid container>
									<Grid item key="TitleTextSub" style={{ marginTop: "7px" }}>
										<HeaderTextSub pallet={pallet}>TEMA</HeaderTextSub>
									</Grid>
									<Grid item style={{ marginLeft: "5px" }}>
										<TitleTextSub pallet={pallet}>
											<Link href="#" onClick={() => navigate(`/classificacaodetemas/subtema/${Number(infoTema.idTema)}`)}
												color="inherit"
												underline="none"
											>
												{infoTema.descricaoTema}
											</Link>
										</TitleTextSub>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item key="TitleTextSub" style={{ marginTop: "7px" }}>
										<HeaderTextSub pallet={pallet}>GRUPO</HeaderTextSub>
									</Grid>
									<Grid item style={{ marginLeft: "5px" }}>
										<TitleTextSub pallet={pallet}>
											<Link href="#" onClick={() => navigate(`/classificacaodetemas/tema/${Number(infoTema.idGrupo)}`)}
												color="inherit"
												underline="none"
											>
												{infoTema.descricaoGrupo}
											</Link>
										</TitleTextSub>
									</Grid>
								</Grid>

								{
									infoTema.prioridade ?
										<>
											<Grid container>
												<Grid item key="TitleTextSub" style={{ marginTop: "7px" }}>
													<HeaderTextSub pallet={pallet}>PRIORIDADE</HeaderTextSub>
												</Grid>
												<Grid item style={{ marginLeft: "5px" }}>
													<TitleTextSub pallet={pallet}>
														<Link href="#" onClick={() => navigate(`/classificacaodetemas/tema/${Number(infoTema.idGrupo)}`)}
															color="inherit"
															underline="none"
														>
															<ChipStyle pallet={pallet}>
																<Grid style={{ display: "flex", justifyContent: "space-between " }} >
																	<DotStyle
																		bg={
																			prioridadeColor(prioridadeDescricao(infoTema.prioridade))
																		}
																	>
																	</DotStyle>
																	<Grid>
																		{infoTema.prioridade ? prioridadeDescricao(infoTema.prioridade) : "Não Classificado"}
																	</Grid>
																</Grid>
															</ChipStyle>
														</Link>
													</TitleTextSub>
												</Grid>
											</Grid>
										</>
										:
										null
								}

								<Grid container spacing={2}>
									<Grid item xl={6} lg={6} xs={12} style={{
										marginTop: "10px",
										// flexWrap: "nowrap",
									}}>
										<SummaryPanelWidget
											title="TÓPICO"
											items={[
												{
													value: infoTema.totalTopicos | 0,
													label: "Cadastrados",
												},
												{
													value: infoTema.totalTopicosAtivos | 0,
													label: "Ativos",
												},
											]}
										/>
									</Grid>
								</Grid>
							</HeaderContentPaper>
						</Grid>
					</ContainerHead>
					{loading
						?
						<CircularLoading
							size={80}
							top={"90%"}
							left={"50%"}
							zIndex={2}

						/>
						:
						<ContainerBody container>
							<Grid item style={{ width: "100%" }}>
								<Paper style={{
									borderRadius: "6px",
									paddingTop: `${isMobile ? "0px" : "0px"}`
								}}>
									<TCTemasCorporativosTopicoList
										idSubtema={idSubtemaParm}
										loading={loadTopicosList}
									/>

								</Paper>
							</Grid>
						</ContainerBody>
					}
					<ContainerFooter container>
					</ContainerFooter>
				</>
			</Main>
		</div>
	);
};

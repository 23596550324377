import * as React from 'react';
import TextField from '@mui/material/TextField';
import useStyles from './layout/Styles';
import { usePallet } from '../contexts/PalletContext';
import ProposicaoService from '../services/ProposicaoService';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBRLocale from "date-fns/locale/pt-BR";
import { InputAdornment } from '@mui/material';


export default function InputDatePicker(
    props: {
        value: string,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
        id: string,
        label: string,
        inputFormat: string,
        defaultValue?: Date,
        startAdornment?: React.ReactNode,
        minDate?: any
        placeholder?: string
    }) {

    const classes = useStyles();
    const { pallet } = usePallet();

    return (
        <LocalizationProvider locale={ptBRLocale} dateAdapter={AdapterDateFns}>
            <MobileDatePicker
                minDate={props?.minDate}
                inputFormat={props.inputFormat}
                label={props.label}
                // toolbarPlaceholder={props.inputFormat.toLowerCase()}
                value={props.defaultValue ? props.defaultValue : props?.value}
                onChange={props.onChange}
                renderInput={(params) => <TextField {...params}
                    placeholder={props.placeholder}
                    style={{ width: "100%" }}
                    id={props.id}
                    variant="outlined"
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                        },
                        style: {
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_100,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {props.startAdornment ? props.startAdornment : ""}
                            </InputAdornment>
                        )
                    }}
                    InputLabelProps={{
                        style: { color: "grey" }
                    }}
                />}
            />
        </LocalizationProvider >
    );
}
import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PlaceIcon from '@material-ui/icons/Place';
import CallIcon from '@material-ui/icons/Call';
import { IParlamentar } from "../../../models/Parlamentar.Model"
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import { calculaIdade } from "../../../utils/dateUtils";
import format from "date-fns/format";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useAlert } from '../../../contexts/AlertContext';
// import ParlamentarService from '../../../services/ParlamentarService';
const CustomGrid = (props: { children: React.ReactNode, style?: any }): JSX.Element => {
    const { pallet } = usePallet();
    return (
        <Grid style={{
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `${pallet.fontSize.text_10}`,
            ...props.style
        }}>
            {props.children}
        </Grid>
    )
}

const accessLink = (url) => {
    window.open(url, '_blank').focus();
};

export const ParlamentarConsultaInformacoes = (props: { parlamentar: IParlamentar }) => {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    // useEffect(() => {
    //     TabelasService(event.target.value).then(
    //         res => {
    //             const data = []
    //             data.push({ key: '', label: '-- Selecione -- ', value: '' });
    //             res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.nome }))
    //             setArrayMunicipioNascimento(() => [...data]);
    //         })
    // }, []);
    let dataNascParlamentar = undefined;
    const idadeParlamentar = calculaIdade(new Date(props.parlamentar?.data_nascimento))
    if (props.parlamentar?.data_nascimento)
        dataNascParlamentar = format(new Date(props.parlamentar.data_nascimento), 'dd/MM/yyyy')
    return (
        <Grid style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, paddingRight: `${isMobile ? "20px" : "50px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
            <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        flexFlow: "row-reverse",
                    }}
                >
                    <Typography className={classes.heading} style={{
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size14}`,
                        fontWeight: pallet.general.weight_600,
                        marginLeft: "10px",
                    }}>
                        INFORMAÇÕES
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    fontFamily: `${pallet.general.fontFamily}`
                }}>
                    {/* <MyComponent /> */}
                    <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"}`, width: "100%" }}>
                        <Grid xl={12}>
                            <CustomGrid style={{ marginBottom: "30px", fontSize: `${pallet.fontSize.titleWidgets}` }}>
                                DADOS PESSOAIS
                            </CustomGrid>
                            <Grid style={{ marginBottom: "10px" }}>{props.parlamentar?.data_falecimento ? "✝ Falecido" : null}</Grid>
                            <Grid xl={12} container justifyContent="flex-start">
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", marginTop: "10px", fontWeight: pallet.general.weight_600 }}>
                                        IDADE
                                    </CustomGrid>
                                    <Grid style={{ color: "black", fontWeight: 100 }}>{idadeParlamentar ? idadeParlamentar +  ' anos' : "Não Informada"}</Grid>
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        DATA DE NASCIMENTO
                                    </CustomGrid>
                                    {<Grid>{dataNascParlamentar ? dataNascParlamentar : "Não Informada"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        ESTADO DE NASCIMENTO
                                    </CustomGrid>
                                    {<Grid>{props.parlamentar?.uf_nascimento || "Não Informado"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        MUNICÍPIO DE NASCIMENTO
                                    </CustomGrid>
                                    {<Grid>{props?.parlamentar?.municipio_nascimento || "Não Informado"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        ESCOLARIDADE
                                    </CustomGrid>
                                    {<Grid>{props.parlamentar?.escolaridade || "Não Informada"}</Grid>}
                                </Grid>
                                <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                    <CustomGrid style={{ color: "black", marginBottom: "6px", fontWeight: `${pallet.fontWeight.subtitle}` }}>
                                        PROFISSÃO
                                    </CustomGrid>
                                    <Grid>
                                        {props.parlamentar?.profissoes && props.parlamentar?.profissoes?.length > 0 ?
                                            props.parlamentar?.profissoes?.map(item => {
                                                return (

                                                    item.label

                                                )
                                            }).join(", ")
                                            :
                                            <Grid>
                                                Não Informada
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xl={12} style={{ paddingTop: "20px" }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                marginBottom: "15px"
                            }}>
                                CONTATO
                            </Grid>
                            <Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                                    <Grid item>
                                        <EmailIcon style={{
                                            fontSize: `${isMobile ? "18px" : "22px"}`,
                                            marginTop: "0",
                                            marginRight: "5px"
                                        }} />
                                    </Grid>
                                    <Grid item  >
                                        {props?.parlamentar?.emails.map((x, index) => {
                                            return (
                                                <Grid container>
                                                    <Grid style={{ fontSize: `${isMobile ? "13px" : "14px"}`, fontWeight: pallet.fontWeight.subtitle, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                        Email {index + 1}
                                                    </Grid>
                                                    <Grid style={{ fontSize: `${isMobile ? "13px" : "14px"}`, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                        {x.label}
                                                    </Grid>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "15px" }}>
                                    <Grid item>
                                        <CallIcon style={{
                                            fontSize: `${isMobile ? "18px" : "22px"}`,
                                            marginTop: `${isMobile ? "5px" : "0px"}`
                                        }} />
                                    </Grid>
                                    <Grid item  >
                                        {props?.parlamentar?.telefones.map((x, index) => {
                                            return (
                                                <Grid container>
                                                    <Grid style={{ fontSize: `${isMobile ? "13px" : "14px"}`, fontWeight: pallet.fontWeight.subtitle, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                        Telefone {index + 1}
                                                    </Grid>
                                                    <Grid style={{ fontSize: `${isMobile ? "13px" : "14px"}`, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                        {x.label}
                                                    </Grid>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid container justifyContent="space-between" style={{
                                paddingTop: `${isMobile ? "5px" : "10px"}`,
                            }}>
                                <Grid container style={{ width: "100%", marginTop: "5px" }}>
                                    <Box display="flex" style={{ marginRight: "20px" }}>
                                        <Grid>
                                            <PlaceIcon style={{
                                                fontSize: `${isMobile ? "18px" : "22px"}`,
                                                marginTop: `${isMobile ? "5px" : "0px"}`
                                            }} />
                                        </Grid>
                                        {props.parlamentar?.endereco && props.parlamentar?.endereco !== '' ?
                                            <Grid style={{ marginLeft: "5px" }}>
                                                {props.parlamentar?.endereco}
                                            </Grid>
                                            :
                                            <Grid style={{ marginLeft: "5px" }}>
                                                Endereço não informado
                                            </Grid>
                                        }
                                    </Box>
                                </Grid>
                                <Grid container style={{ width: "100%", marginTop: "20px" }}>
                                    <Box display="flex" style={{ marginRight: "20px" }}>
                                        <Grid>
                                            <InsertLinkIcon style={{
                                                fontSize: "25px",
                                                marginTop: `${isMobile ? "0px" : "-1px"}`
                                            }} />
                                        </Grid>
                                        {props.parlamentar?.uri && props.parlamentar?.uri !== '' ?
                                            <Grid style={{ marginLeft: "5px" }}>
                                                <a target="_blank" style={{ textDecoration: "none" }} href={props.parlamentar?.uri}>Biografia Completa</a>
                                            </Grid>
                                            :
                                            <Grid style={{ marginLeft: "5px" }}>
                                                Biografia não informada.
                                            </Grid>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {props?.parlamentar?.social?.rede1 || props?.parlamentar?.social?.rede2 || props?.parlamentar?.social?.rede3 ?
                            <Grid xl={12} style={{ paddingTop: "20px" }}>
                                <Grid style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.fontSize.titleWidgets}`
                                }}>
                                    REDES SOCIAIS
                                </Grid>
                                <Grid container justifyContent="space-between" style={{ width: "150px" }}>
                                    {props?.parlamentar?.social?.rede1 ?
                                        <Grid onClick={() => accessLink(props?.parlamentar?.social?.rede1)} style={{ paddingTop: "10px", cursor: 'pointer' }}>
                                            <Facebook style={{
                                                color: "#1778F2",
                                                fontSize: "35px"
                                            }} />
                                        </Grid>
                                        : " "}
                                    {props?.parlamentar?.social?.rede2 ?
                                        <Grid onClick={() => accessLink(props?.parlamentar?.social?.rede2)} style={{ paddingTop: "12px", cursor: 'pointer' }}>
                                            <div style={{
                                                width: "31px",
                                                height: "30px",
                                                backgroundImage: "linear-gradient(to top right, #FEDA77, #F58529, #DD2A7B, #8134AF, #515BD4)",
                                                borderRadius: "8px"
                                            }}>
                                                <Instagram style={{
                                                    marginTop: "0px",
                                                    marginLeft: "0.5px",
                                                    color: "white",
                                                    fontSize: "30px"
                                                }} />
                                            </div>
                                        </Grid>
                                        : " "}
                                    {props?.parlamentar?.social?.rede3 ?
                                        // <Grid onClick={() => accessLink(`${'https://twitter.com/' + props?.parlamentar?.social?.rede3}`)} style={{ paddingTop: "10px", cursor: 'pointer' }}>
                                        <Grid onClick={() =>  NewAlert("error","Twitter está temporariamente indisponível.")} style={{ paddingTop: "10px", cursor: 'pointer' }}>

                                            <Twitter style={{
                                                color: "#4ECEF6",
                                                fontSize: "35px"
                                            }} />
                                        </Grid>
                                        : " "}
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}
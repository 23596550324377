import {
    AccordionSummary,
    Box,
    Divider,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import styled from "styled-components";
import Main from "../main/Main";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import { isMobile } from "react-device-detect";
import CalendarOutlineIcon from "@mui/icons-material/CalendarTodayOutlined";
import DateRangeSelector from "../../components/DateRangeSelector";
import { subYears } from "date-fns";
import TiposList, { IFiltroTipoProps } from "../insights/_components/TiposLista";
import LineTitle from "./_components/LineTitle";
import { useAuthorization } from "../../contexts/AuthorizationContext";


export default function Relatorios() {
    const classes = useStyles();
    const { pallet } = usePallet();
    const [dtIni, setDtIni] = React.useState<Date>(subYears(new Date(), 1));
    const [dtFin, setDtFin] = React.useState<Date>(new Date());
    const [esfSel, setEsfSel] = useState("Federal");
    const [itens, setItens] = React.useState([]);
    const { user } = useAuthorization();

    React.useEffect(() => {

        setItens(user.id_permissao !== 4 ? [
            //#### Federal ###
            //###### Proposições
            { key: 1, label: "PROPOSIÇÕES APRESENTADAS POR CASA LEGISLATIVA", type: 'proposicao', path: `/relatorio/proposicao/casas/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 2, label: "PROPOSIÇÕES CLASSIFICADAS PELO NÍVEL DE PRIORIDADE", type: 'proposicao', path: `/relatorio/proposicao/prioridades/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 3, label: "PROPOSIÇÕES CLASSIFICADAS PELO POSICIONAMENTO", type: 'proposicao', path: `/relatorio/proposicao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 4, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            // { key: 5, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS LEGISLATIVOS", type: 'proposicao', path: `/relatorio/proposicao/propsportemaslegislativos/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 5, label: "AÇÕES (PREPARATÓRIAS E DE INFLUENCIAS) REALIZADAS POR PERÍODO", type: 'proposicao', path: `/relatorio/proposicao/acoesporperiodo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 6, label: "AÇÕES DE INFLUÊNCIA POR RESULTADO", type: 'proposicao', path: `/relatorio/proposicao/acaoinfluencia/resultado/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },            
            { key: 8, label: "AÇÕES DE INFLUÊNCIA CRIADA/RESPONSÁVEL POR " + `${user?.nome.toUpperCase()}`, type: 'proposicao', path: `/relatorio/proposicao/acaoinfluencia/resultadoporfulano/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 9, label: "AÇÕES DE INFLUÊNCIA REALIZADAS POR UNIDADE DE NEGÓCIO", type: 'proposicao', path: `/relatorio/proposicao/acaoinfluencia/unidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 10, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 11, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS LEGISLATIVOS", type: 'proposicao', path: `/relatorio/proposicao/dividida/tema/legislativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 12, label: "PROPOSIÇÕES APRESENTADAS POR PERÍODO", type: 'proposicao', path: `/relatorio/proposicao/apresentadas/periodo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 13, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 14, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //###### Interlocutores
            { key: 15, label: "INTERLOCUTORES DISTRIBUÍDOS POR QUALIFICAÇÃO", type: 'interlocutor', path: `/relatorio/interlocutor/qualificacao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 16, label: "INTERLOCUTORES DISTRIBUÍDOS POR PARTIDO POLÍTICO", type: 'interlocutor', path: `/relatorio/interlocutor/partido/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 17, label: "INTERLOCUTORES CLASSIFICADOS POR PROFISSÃO", type: 'interlocutor', path: `/relatorio/interlocutor/profissao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 18, label: "INTERLOCUTORES DIVIDIDOS POR CASAS LEGISLATIVAS", type: 'interlocutor', path: `/relatorio/interlocutor/casas/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 19, label: "INTERLOCUTORES DIVIDIDOS POR ORGÃOS TÉCNICOS", type: 'interlocutor', path: `/relatorio/interlocutor/orgaotecnico/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 20, label: "INTERLOCUTORES ENVOLVIDOS EM AÇÕES DE INFLUÊNCIA", type: 'interlocutor', path: `/relatorio/interlocutor/acaoinfluencia/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //#### Estadual ###
            //###### Proposições
            { key: 21, label: "PROPOSIÇÕES APRESENTADAS POR ASSEMBLEIA LEGISLATIVA", type: 'proposicao_est', path: `/relatorio/proposicao/casas/estadual/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 22, label: "PROPOSIÇÕES CLASSIFICADAS PELO NÍVEL DE PRIORIDADE", type: 'proposicao_est', path: `/relatorio/proposicao/prioridades/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 23, label: "PROPOSIÇÕES CLASSIFICADAS PELO POSICIONAMENTO", type: 'proposicao_est', path: `/relatorio/proposicao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 24, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao_est', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 25, label: "AÇÕES (PREPARATÓRIAS E DE INFLUENCIAS) REALIZADAS POR PERÍODO", type: 'proposicao_est', path: `/relatorio/proposicao/acoesporperiodo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 26, label: "AÇÕES DE INFLUÊNCIA POR RESULTADO", type: 'proposicao_est', path: `/relatorio/proposicao/acaoinfluencia/resultado/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 27, label: "AÇÕES DE INFLUÊNCIA CRIADA/RESPONSÁVEL POR " + `${user?.nome.toUpperCase()}`, type: 'proposicao_est', path: `/relatorio/proposicao/acaoinfluencia/resultadoporfulano/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 28, label: "AÇÕES DE INFLUÊNCIA REALIZADAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_est', path: `/relatorio/proposicao/acaoinfluencia/unidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 29, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_est', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 30, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao_est', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 31, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao_est', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //###### Interlocutores
            { key: 32, label: "INTERLOCUTORES DISTRIBUÍDOS POR QUALIFICAÇÃO", type: 'interlocutor_est', path: `/relatorio/interlocutor/qualificacao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 33, label: "INTERLOCUTORES DISTRIBUÍDOS POR PARTIDO POLÍTICO", type: 'interlocutor_est', path: `/relatorio/interlocutor/partido/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 34, label: "INTERLOCUTORES CLASSIFICADOS POR PROFISSÃO", type: 'interlocutor_est', path: `/relatorio/interlocutor/profissao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 35, label: "INTERLOCUTORES DIVIDIDOS POR ASSEMBLEIA LEGISLATIVA", type: 'interlocutor_est', path: `/relatorio/interlocutor/casas/estadual/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 36, label: "INTERLOCUTORES ENVOLVIDOS EM AÇÕES DE INFLUÊNCIA", type: 'interlocutor_est', path: `/relatorio/interlocutor/acaoinfluencia/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //#### Municipal ###
            //###### Proposições
            { key: 37, label: "PROPOSIÇÕES APRESENTADAS POR CAMARA MUNICIPAL", type: 'proposicao_mun', path: `/relatorio/proposicao/casas/municipal/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 38, label: "PROPOSIÇÕES CLASSIFICADAS PELO NÍVEL DE PRIORIDADE", type: 'proposicao_mun', path: `/relatorio/proposicao/prioridades/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 39, label: "PROPOSIÇÕES CLASSIFICADAS PELO POSICIONAMENTO", type: 'proposicao_mun', path: `/relatorio/proposicao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 40, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao_mun', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 41, label: "AÇÕES (PREPARATÓRIAS E DE INFLUENCIAS) REALIZADAS POR PERÍODO", type: 'proposicao_mun', path: `/relatorio/proposicao/acoesporperiodo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 42, label: "AÇÕES DE INFLUÊNCIA POR RESULTADO", type: 'proposicao_mun', path: `/relatorio/proposicao/acaoinfluencia/resultado/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 43, label: "AÇÕES DE INFLUÊNCIA CRIADA/RESPONSÁVEL POR " + `${user?.nome.toUpperCase()}`, type: 'proposicao_mun', path: `/relatorio/proposicao/acaoinfluencia/resultadoporfulano/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 44, label: "AÇÕES DE INFLUÊNCIA REALIZADAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_mun', path: `/relatorio/proposicao/acaoinfluencia/unidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 45, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_mun', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 46, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao_mun', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 47, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao_mun', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //###### Interlocutores
            { key: 48, label: "INTERLOCUTORES DISTRIBUÍDOS POR QUALIFICAÇÃO", type: 'interlocutor_mun', path: `/relatorio/interlocutor/qualificacao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },            
            { key: 49, label: "INTERLOCUTORES DISTRIBUÍDOS POR PARTIDO POLÍTICO", type: 'interlocutor_mun', path: `/relatorio/interlocutor/partido/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 50, label: "INTERLOCUTORES CLASSIFICADOS POR PROFISSÃO", type: 'interlocutor_mun', path: `/relatorio/interlocutor/profissao/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 51, label: "INTERLOCUTORES DIVIDIDOS POR CAMARA MUNICIPAL", type: 'interlocutor_mun', path: `/relatorio/interlocutor/casas/municipal/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 52, label: "INTERLOCUTORES ENVOLVIDOS EM AÇÕES DE INFLUÊNCIA", type: 'interlocutor_mun', path: `/relatorio/interlocutor/acaoinfluencia/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },

            
        ]
        :
        [
            //#### Federal ###
            //###### Proposições
            { key: 1, label: "PROPOSIÇÕES APRESENTADAS POR CASA LEGISLATIVA", type: 'proposicao', path: `/relatorio/proposicao/casas/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            // { key: 2, label: "PROPOSIÇÕES CLASSIFICADAS PELO NÍVEL DE PRIORIDADE", type: 'proposicao', path: `/relatorio/proposicao/prioridades/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            // { key: 3, label: "PROPOSIÇÕES CLASSIFICADAS PELO POSICIONAMENTO", type: 'proposicao', path: `/relatorio/proposicao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 2, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            // { key: 5, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS LEGISLATIVOS", type: 'proposicao', path: `/relatorio/proposicao/propsportemaslegislativos/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 3, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 4, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS LEGISLATIVOS", type: 'proposicao', path: `/relatorio/proposicao/dividida/tema/legislativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 5, label: "PROPOSIÇÕES APRESENTADAS POR PERÍODO", type: 'proposicao', path: `/relatorio/proposicao/apresentadas/periodo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 6, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 7, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            //#### Estadual ###
            //###### Proposições
            { key: 8, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao_est', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 9, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao_est', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 10, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao_est', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 11, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_est', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },

            //#### Municipal ###
            //###### Proposições
            { key: 12, label: "PROPOSIÇÕES CLASSIFICADAS POR ATIVO OU INATIVO", type: 'proposicao_mun', path: `/relatorio/proposicao/atividade/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 13, label: "PROPOSIÇÕES DIVIDIDAS POR TEMAS CORPORATIVOS", type: 'proposicao_mun', path: `/relatorio/proposicao/dividida/tema/corporativo/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 14, label: "PROPOSIÇÕES QUE MAIS TIVERAM MOVIMENTAÇÃO", type: 'proposicao_mun', path: `/relatorio/proposicao/movimentacao/posicionamento/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
            { key: 15, label: "MANIFESTAÇÃO DAS AÇÕES PREPARATÓRIAS POR UNIDADE DE NEGÓCIO", type: 'proposicao_mun', path: `/relatorio/proposicao/manifestacoesporunidade_de_negocio/${dtIni}/${dtFin}?filter=[{"key": "esfera", "value": "${esfSel}"}]` },
                     
        ])
    }, [esfSel, dtIni, dtFin]);

    const [tipoFilter, setTipoFilter] = React.useState<Array<IFiltroTipoProps>>([
        { key: "todos", label: "TODOS", active: true },
        { key: "proposicao", label: "PROPOSIÇÃO", active: false },
        { key: "interlocutor", label: "INTERLOCUTOR", active: false },
    ]);
    
    const [tipo, setTipo] = React.useState<Array<IFiltroTipoProps>>([
        { key: "todos", label: "TODOS", active: true },
        { key: "proposicao", label: "PROPOSIÇÃO", active: false },
        { key: "interlocutor", label: "INTERLOCUTOR", active: false },
        { key: "todos_est", label: "TODOS", active: true },
        { key: "proposicao_est", label: "PROPOSIÇÃO", active: false },
        { key: "interlocutor_est", label: "INTERLOCUTOR", active: false },
        { key: "todos_mun", label: "TODOS", active: true },
        { key: "proposicao_mun", label: "PROPOSIÇÃO", active: false },
        { key: "interlocutor_mun", label: "INTERLOCUTOR", active: false },
    ]);


    const [tipoAtual, setTipoAtual] = React.useState("TODOS")

    const handleEsfera = (event, newSelEsf) => {

        
        //Ao Mudar Esfera, mudo o tipo Atual pra todos
        if (newSelEsf === 'Federal') {
            setTipoAtual("todos")
        } else if (newSelEsf === 'Estadual') {
            setTipoAtual("todos_est")
        } else {
            setTipoAtual("todos_mun")
        }


        //setTipoAtual("todos")
        setEsfSel(newSelEsf)

    };

    React.useEffect(() => {

        onChangeTipo(tipoAtual)
    }, [esfSel]);

    const onChangeDate = (dataInicio: Date, dataFinal: Date) => {
        // console.log("onchangeDate",dataInicio,dataFinal )
        setDtIni(dataInicio);
        setDtFin(dataFinal);
    };


    const onGetType = (target: any) => {

        let getType = { esfera: '', tipo: '', origem: '' }

        

        if (target === 'proposicao' || target === 'proposição' || target === 'PROPOSICAO' || target === 'PROPOSIÇÃO') {

            getType.origem = 'proposicao'

            if (esfSel === 'Federal') {
                getType.esfera = 'Federal'
                getType.tipo = 'proposicao'
            } else if (esfSel === 'Estadual') {
                getType.esfera = 'Estadual'
                getType.tipo = 'proposicao_est'
            } else {
                getType.esfera = 'Municipal'
                getType.tipo = 'proposicao_mun'
            }

        } else if (target === 'todos' || target === 'TODOS' || target === 'todos_est' || target === 'TODOS_EST'|| target === 'TODOS_MUN'|| target === 'todos_mun') {

            getType.origem = 'todos'
            if (esfSel === 'Federal') {
                getType.esfera = 'Federal'
                getType.tipo = 'todos'
            } else if (esfSel === 'Estadual') {
                getType.esfera = 'Estadual'
                getType.tipo = 'todos_est'
            } else {
                getType.esfera = 'Municipal'
                getType.tipo = 'todos_mun'
            }

        } else {

            getType.origem = 'interlocutor'
            if (esfSel === 'Federal') {
                getType.esfera = 'Federal'
                getType.tipo = 'interlocutor'
            } else if (esfSel === 'Estadual') {
                getType.esfera = 'Estadual'
                getType.tipo = 'interlocutor_est'
            } else {
                getType.esfera = 'Muncipal'
                getType.tipo = 'interlocutor_mun'
            }
        }

        return getType;

    }

    const onChangeTipo = (event: any) => {


        let getType

        

        if (event === 'todos' || event === 'TODOS' || event === 'todos_mun' || event === 'TODOS_MUN' || event === 'todos_est' || event === 'TODOS_EST' || event === 'proposicao' || event === 'proposição' || event === 'PROPOSICAO' || event === 'PROPOSIÇÃO' || event === 'interlocutor' || event === 'INTERLOCUTOR') {

            getType = onGetType(event)


            setTipoFilter(
                tipoFilter.map((x) => {
                    if (x.key.toLowerCase() === getType.origem.toLowerCase()) {
                        x.active = true;
                        setTipoAtual(getType.tipo);
                    }
                    else if (x.active) x.active = false;
                    return x;
                })
            );
        } else {

            
            getType = onGetType(event.target.title)



            setTipoFilter(
                tipoFilter.map((x) => {
                    if (x.label.toLowerCase() === event.target.title.toLowerCase()) {
                        x.active = true;
                        setTipoAtual(getType.tipo);
                    }
                    else if (x.active) x.active = false;
                    return x;
                })
            );
        }
    };

    const TitleText = styled(Typography)`
      color: ${(props) => props.pallet.textColorQuaternary};
      font-weight: ${(props) => props.pallet.general.weight_bold};
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 1px;
    `;

    ///////////////
    //Listas
    ////////

    //////////////
    //Federal//

    //Proposicao
    let listPropFilter = itens.filter((x) => {
        return x.type === 'proposicao'
    })

    //Interlocutor
    let listInterFilter = itens.filter((x) => {
        return x.type === 'interlocutor'
    })

    //Todos
    let listTodosFilter = itens.filter((x) => {
        return x.type === 'proposicao' || x.type === 'interlocutor'
    })


    //////////////
    //Estadual//

    //Proposicao
    let listPropFilterEst = itens.filter((x) => {
        return x.type === 'proposicao_est'
    })

    //Interlocutor
    let listInterFilterEst = itens.filter((x) => {
        return x.type === 'interlocutor_est'
    })

    //Todos
    let listTodosFilterEst = itens.filter((x) => {
        return x.type === 'proposicao_est' || x.type === 'interlocutor_est'
    })

    //////////////
    //Municipal//

    //Proposicao
    let listPropFilterMun = itens.filter((x) => {
        return x.type === 'proposicao_mun'
    })

    //Interlocutor
    let listInterFilterMun = itens.filter((x) => {
        return x.type === 'interlocutor_mun'
    })

    //Todos
    let listTodosFilterMun = itens.filter((x) => {
        return x.type === 'proposicao_mun' || x.type === 'interlocutor_mun'
    })



    // console.log('prop', listPropFilter)
    // console.log('inter', listInterFilter)
    // console.log('todos', listTodosFilter)

    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid maxWidth={"100%"} container>
                        <Grid item xs={12} key="TitleText">
                            <TitleText pallet={pallet}>Relatórios</TitleText>
                        </Grid>

                        <Grid container style={{ paddingTop: "30px" }}>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{
                                    backgroundImage: `url(${filtersBackgroundImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    padding: "25px",
                                    borderRadius: "5px",
                                    boxShadow:
                                        "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                }}
                            >
                                <Grid item>
                                    <Grid style={{ marginBottom: "5px" }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}
                                        >
                                            PERÍODO
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        style={{
                                            maxWidth: `${isMobile ? "100%" : "300px"}`,
                                            width: "250px",
                                            background: "#4B1196",
                                            borderRadius: "5px",
                                            justifyContent: `${isMobile ? "flex-start" : "center"}`,
                                        }}
                                    >
                                        <Grid item>
                                            <CalendarOutlineIcon
                                                style={{
                                                    color: `${pallet.color.primary.font}`,
                                                    marginTop: "6px",
                                                    fontSize: "17px",
                                                    marginRight: "0px",
                                                    marginLeft: `${isMobile ? "6px" : "5px"}`,
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                paddingRight: "10px",
                                                color: `${pallet.color.primary.font}`,
                                            }}
                                        >
                                            <DateRangeSelector
                                                startDate={dtIni}
                                                endDate={dtFin}
                                                onChange={onChangeDate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        marginLeft: `${isMobile ? "0px" : "30px"}`,
                                        marginTop: `${isMobile ? "10px" : "0px"}`,
                                    }}
                                >
                                    <Grid style={{ marginBottom: "5px" }}>
                                        <Typography
                                            style={{
                                                color: `${pallet.color.secundary.font}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: pallet.fontWeight.subtitle,
                                            }}
                                        >
                                            ESFERAS
                                        </Typography>
                                    </Grid>

                                    <Grid container alignItems="center" direction="row">
                                        <Grid item>
                                            <Box display="block">
                                                <Grid
                                                    style={{
                                                        backgroundColor: "#fcfcfc",
                                                        borderRadius: "4px",
                                                    }}
                                                >
                                                    <ToggleButtonGroup
                                                        value={esfSel}
                                                        exclusive={true}
                                                        onChange={handleEsfera}
                                                        aria-label="sphere"
                                                        size="small"
                                                    >
                                                        <ToggleButton
                                                            value="Federal"
                                                            aria-label="device"
                                                            // sx={{
                                                            //     "&.Mui-selected": {
                                                            //         color: `${pallet.textColorPrimary}`,
                                                            //         backgroundColor: "#4B1196",
                                                            //     },
                                                            // }}

                                                            sx={{
                                                                "&.Mui-selected": {
                                                                  color: `${pallet.textColorPrimary}`,
                                                                  backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                },
                                                                "&.Mui-selected:hover": {
                                                                  backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                                              },
                                                              }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: "14px",
                                                                    textTransform: "capitalize",
                                                                    paddingLeft: "4px",
                                                                    paddingRight: "4px",
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontWeight: pallet.general.weight_600,
                                                                }}
                                                            >
                                                                Federal
                                                            </Typography>
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="Estadual"
                                                            aria-label="device"
                                                            // sx={{
                                                            //     "&.Mui-selected": {
                                                            //         color: `${pallet.textColorPrimary}`,
                                                            //         backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                            //     },
                                                            // }}
                                                            sx={{
                                                                "&.Mui-selected": {
                                                                  color: `${pallet.textColorPrimary}`,
                                                                  backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                },
                                                                "&.Mui-selected:hover": {
                                                                  backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                                              },
                                                              }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: "14px",
                                                                    textTransform: "capitalize",
                                                                    paddingLeft: "4px",
                                                                    paddingRight: "4px",
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontWeight: pallet.general.weight_600,
                                                                }}
                                                            >
                                                                Estadual
                                                            </Typography>
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="Municipal"
                                                            aria-label="device"
                                                            // sx={{
                                                            //     "&.Mui-selected": {
                                                            //         color: `${pallet.textColorPrimary}`,
                                                            //         backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                            //     },
                                                            // }}
                                                            sx={{
                                                                "&.Mui-selected": {
                                                                  color: `${pallet.textColorPrimary}`,
                                                                  backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                                },
                                                                "&.Mui-selected:hover": {
                                                                  backgroundColor: isMobile ? `${pallet.backgroundColorPrimary}` : "#4B1196",
                                                              },
                                                              }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: "14px",
                                                                    textTransform: "capitalize",
                                                                    paddingLeft: "4px",
                                                                    paddingRight: "4px",
                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                    fontWeight: pallet.general.weight_600,
                                                                }}
                                                            >
                                                                Municipal
                                                            </Typography>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Paper
                            style={{ borderRadius: "7px", width: "100%", marginTop: "35px" }}
                        >
                            {
                                !isMobile && user.id_permissao !== 4 ?

                                    <Grid
                                        style={{
                                            borderRadius: "7px",
                                            padding: "25px",
                                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                        }}
                                    >
                                        <TiposList filtros={tipoFilter} onChange={onChangeTipo} />
                                    </Grid>
                                    : isMobile && user.id_permissao !== 4 ?
                                    <Grid container flex-direction={'column'} textAlign={'center'} display={'inline-grid'}
                                        style={{
                                            borderRadius: "7px",
                                            padding: "25px",
                                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                        }}
                                    >
                                        <TiposList filtros={tipoFilter} onChange={onChangeTipo} />
                                    </Grid>

                                    :

                                    null

                            }
                            <Grid>
                                <Divider
                                    variant="fullWidth"
                                    style={{
                                        marginBottom: `${isMobile ? "15px" : "30px"}`,
                                    }}
                                />
                            </Grid>
                            <Grid style={{ marginBottom: "25px" }}>
                                {
                                    (tipoAtual === 'PROPOSICAO' || tipoAtual === 'PROPOSIÇÃO' || tipoAtual === 'proposicao' || tipoAtual === 'proposição') ?
                                        listPropFilter.map(xa => {
                                            return (
                                                <LineTitle
                                                    text={xa.label}
                                                    path={xa.path}
                                                    type={xa.type}
                                                />
                                            )
                                        })
                                        :
                                        (tipoAtual === 'INTERLOCUTOR' || tipoAtual === 'interlocutor') ?
                                            listInterFilter.map(xb => {
                                                return (
                                                    <LineTitle
                                                        text={xb.label}
                                                        path={xb.path}
                                                        type={xb.type}
                                                    />
                                                )
                                            })
                                            :
                                            (tipoAtual === 'TODOS' || tipoAtual === 'todos') ?
                                                listTodosFilter.map(xb => {
                                                    return (
                                                        <LineTitle
                                                            text={xb.label}
                                                            path={xb.path}
                                                            type={xb.type}
                                                        />
                                                    )
                                                })
                                                :
                                                (tipoAtual === 'PROPOSICAO_EST' || tipoAtual === 'proposicao_est') ?
                                                    listPropFilterEst.map(xb => {
                                                        return (
                                                            <LineTitle
                                                                text={xb.label}
                                                                path={xb.path}
                                                                type={xb.type}
                                                            />
                                                        )
                                                    })
                                                    :
                                                    (tipoAtual === 'PROPOSICAO_MUN' || tipoAtual === 'proposicao_mun') ?
                                                        listPropFilterMun.map(xb => {
                                                            return (
                                                                <LineTitle
                                                                    text={xb.label}
                                                                    path={xb.path}
                                                                    type={xb.type}
                                                                />
                                                            )
                                                        })
                                                        :
                                                        (tipoAtual === 'INTERLOCUTOR_EST' || tipoAtual === 'interlocutor_est') ?
                                                            listInterFilterEst.map(xb => {
                                                                return (
                                                                    <LineTitle
                                                                        text={xb.label}
                                                                        path={xb.path}
                                                                        type={xb.type}
                                                                    />
                                                                )
                                                            })
                                                            :
                                                            (tipoAtual === 'INTERLOCUTOR_MUN' || tipoAtual === 'interlocutor_mun') ?
                                                                listInterFilterMun.map(xb => {
                                                                    return (
                                                                        <LineTitle
                                                                            text={xb.label}
                                                                            path={xb.path}
                                                                            type={xb.type}
                                                                        />
                                                                    )
                                                                })
                                                                :
                                                                (tipoAtual === 'TODOS_MUN' || tipoAtual === 'todos_mun') ?
                                                                    listTodosFilterMun.map(xb => {
                                                                        return (
                                                                            <LineTitle
                                                                                text={xb.label}
                                                                                path={xb.path}
                                                                                type={xb.type}
                                                                            />
                                                                        )
                                                                    })
                                                                    :
                                                                    listTodosFilterEst.map(xc => {
                                                                        return (
                                                                            <LineTitle
                                                                                text={xc.label}
                                                                                path={xc.path}
                                                                                type={xc.type}
                                                                            />
                                                                        )
                                                                    })
                                }
                            </Grid>

                        </Paper>
                    </Grid>
                </>
            </Main>
        </div >
    );
}

import * as http from '../helpers/http';

class TCService {

  CreateGrupo = async (data: any): Promise<number> => {
    const result = await http.post(`/tc/grupos`, data);
    if (result.success) { return result.data }
    return 0;
  };

  CreateTema = async (id_grupo: number, data: any): Promise<number> => {
    const result = await http.post(`/tc/tema/${id_grupo}`, data);
    if (result.success) { return result.data }
    return 0;
  };

  CreateSubtema = async (id_tema: number, data: any): Promise<number> => {
    const result = await http.post(`/tc/subtema/${id_tema}`, data);
    if (result.success) { return result.data }
    return 0;
  };

  CreateTopico = async (id_subtema: number, data: any): Promise<number> => {
    const result = await http.post(`/tc/topico/${id_subtema}`, data);
    if (result.success) { return result.data }
    return 0;
  };

  setTemasRelacionados = async (id_tema: number, temas_relacionados: number[]): Promise<any> => {
    const result = await http.post(`/ia/temas-relacionados/${id_tema}`, temas_relacionados);

    if (result.success) {
      return result.data
    }
    return undefined;
  };

  getTemasRelacionados = async (id?: number): Promise<any> => {
    const result = await http.get(`/ia/temas-relacionados/${id}`);
    if (result.success) { return result.data }
    return undefined;
  };


  // getTemas = async (pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
  //   const result = await http.get(`/ia/temas-relacao?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
  //   if (result.success) {
  //     return {
  //       success: true,
  //       listInfo: {
  //         pageSize: result.data.pageSize,
  //         pageIndex: result.data.pageIndex,
  //         pageItens: result.data.pageItens,
  //         totalItens: result.data.totalItens,
  //         totalPages: result.data.totalPages
  //       },
  //       data: result.data.data
  //     }
  //   }
  //   return undefined;
  // };



  putGrupoActive = async (id: number, data: any): Promise<boolean> => {
    const result = await http.put(`/tc/grupo/active/${id}`, data);
    if (result.success) { return true }
    return false;
  };

  putTemaActive = async (id: number, data: any): Promise<boolean> => {
    const result = await http.put(`/tc/tema/active/${id}`, data);
    if (result.success) { return true }
    return false;
  };

  putSubTemaActive = async (id: number, data: any): Promise<boolean> => {
    const result = await http.put(`/tc/subtema/active/${id}`, data);
    if (result.success) { return true }
    return false;
  };

  getTopicos = async (idSubtema: number, pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/tc/topicos/${idSubtema}?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {

      return {
        success: true,
        listInfo: {
          pageSize: result.data.infolist.pageSize,
          pageIndex: result.data.infolist.pageIndex,
          pageItens: result.data.infolist.pageItens,
          totalItens: result.data.infolist.totalItens,
          totalPages: result.data.infolist.totalPages
        },
        listInfoTema: {
          id: result.data.infotema.tema_subtemas_id,
          descricao: result.data.infotema.tema_subtemas_descricao,
          descricaoGrupo: result.data.infotema.tema_grupos_descricao,
          descricaoTema: result.data.infotema.temas_proposicao_descricao,
          idTema: result.data.infotema.temas_proposicao_id,
          idGrupo: result.data.infotema.tema_grupos_id,
          ativo: result.data.infotema.temas_proposicao_ativo,
          totalTopicos: result.data.infotema.countTopicos,
          totalTopicosAtivos: result.data.infotema.countTopicosativos,
          prioridade: result.data.infotema.prioridade,
        },
        data: result.data.data
      }
    }
    return undefined;

  };


  getSubTemas = async (idtema: number, pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/tc/subtemas/${idtema}?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {

      return {
        success: true,
        listInfo: {
          pageSize: result.data.infolist.pageSize,
          pageIndex: result.data.infolist.pageIndex,
          pageItens: result.data.infolist.pageItens,
          totalItens: result.data.infolist.totalItens,
          totalPages: result.data.infolist.totalPages
        },
        listInfoTema: {
          id: result.data.infotema.tema_id,
          descricao: result.data.infotema.tema_descricao,
          descricaoGrupo: result.data.infotema.tema_grupo_descricao,
          idGrupo: result.data.infotema.tema_grupo_id,
          ativo: result.data.infotema.tema_ativo,
          totalSubTemas: result.data.infotema.totalSubTemas,
          totalSubTemasAtivos: result.data.infotema.totalSubTemasAtivos,
          totalTopicos: result.data.infotema.totalTopicos,
          totalTopicosAtivos: result.data.infotema.totalTopicosAtivos
        },
        data: result.data.data
      }
    }
    return undefined;

  };

  getTemas = async (idgrupo: number, pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/tc/temas/${idgrupo}?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.infolist.pageSize,
          pageIndex: result.data.infolist.pageIndex,
          pageItens: result.data.infolist.pageItens,
          totalItens: result.data.infolist.totalItens,
          totalPages: result.data.infolist.totalPages
        },
        listInfoGrupo: {
          descricaoGrupo: result.data.infogrupo.descricao_grupo,
          descricaoAtivo: result.data.infogrupo.descricao_ativo,
          totalTemas: result.data.infogrupo.totalTemas,
          totalTemasAtivos: result.data.infogrupo.totalTemasAtivos,
          totalSubTemas: result.data.infogrupo.totalSubTemas,
          totalSubTemasAtivos: result.data.infogrupo.totalSubTemasAtivos,
          totalTopicos: result.data.infogrupo.totalTopicos,
          totalTopicosAtivos: result.data.infogrupo.totalTopicosAtivos
        },
        data: result.data.data
      }
    }
    return undefined;

  };

  GetTema = async (idtema: number): Promise<any> => {
    const result = await http.get(`/tc/tema/${idtema}`);
    if (result.success) {
      return result.data
    }
    return undefined;
  };

  GetSubTema = async (idsubtema: number): Promise<any> => {
    const result = await http.get(`/tc/subtema/${idsubtema}`);
    if (result.success) {

      return {
        success: true,
        listInfoTema: {
          id: result.data.infotema.tema_id,
          descricao: result.data.infotema.tema_descricao,
          grupo_id: result.data.infotema.tema_grupo_id,
          grupo_descricao: result.data.infotema.tema_grupo_descricao,
          ativo: result.data.infotema.tema_ativo,
          totalSubTemas: result.data.infotema.totalSubTemas,
          totalSubTemasAtivos: result.data.infotema.totalSubTemasAtivos
        },
        data: result.data.data
      }

    }
    return undefined;
  };

  GetTopico = async (idTopico: number, idSubtema: number): Promise<any> => {
    const result = await http.get(`/tc/topico/${idTopico}/${idSubtema}`);
    if (result.success) {
      return {
        success: true,
        listInfoTema: {
          id: result.data.infotema.temas_proposicao_id,
          descricao: result.data.infotema.temas_proposicao_descricao,

          grupo_id: result.data.infotema.tema_grupos_id,
          grupo_descricao: result.data.infotema.tema_grupos_descricao,

          ativo: result.data.infotema.tema_ativo,

          subtema_id: result.data.infotema.tema_subtemas_id,

          totalSubTemas: result.data.infotema.totalSubTemas,
          totalSubTemasAtivos: result.data.infotema.totalSubTemasAtivos
        },
        data: result.data.data
      }

    }
    return undefined;
  };

  getGrupos = async (pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
    const result = await http.get(`/tc/grupos?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages
        },
        data: result.data.data
      }
    }
    return undefined;

  };

  GetGrupo = async (idgrupo: number): Promise<any> => {
    const result = await http.get(`/tc/grupo/${idgrupo}`);
    if (result.success) { return result.data }
    return undefined;
  };

  // CreateGrupo = async (data: any, id: number): Promise<number> => {
  //   const result = await http.post(`/proposicoes/${id}/acontecimentos`, data);
  //   if (result.success) { return result.data }
  //   return 0;
  // };

  UpdateGrupo = async (data: any, idgrupo: number): Promise<boolean> => {
    const result = await http.put(`/tc/grupo/${idgrupo}`, data);
    if (result.success) { return result.success }
    return false;
  };

  UpdateTema = async (data: any, idtema: number): Promise<boolean> => {
    const result = await http.put(`/tc/tema/${idtema}`, data);
    if (result.success) { return result.success }
    return false;
  };

  UpdateSubTema = async (data: any, idsubtema: number): Promise<boolean> => {
    const result = await http.put(`/tc/subtema/${idsubtema}`, data);
    if (result.success) { return result.success }
    return false;
  };

  UpdateTopico = async (data: any, idTopico: number): Promise<boolean> => {
    const result = await http.put(`/tc/topico/${idTopico}`, data);
    if (result.success) { return result.success }
    return false;
  };

}

export default new TCService();

import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { TextField, Link, Popover, List, ListItemAvatar, ListItem, ListItemText } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Grid, Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import NotificacoesList, { IFiltroNotificacaoProps } from './NotificacoesList';
import NotificacaoCard from './NotificacaoCard';
import NotificationsContextService, { INotificationsContextResultData } from '../../../services/NotificationsContextService';
import { useAuthorization } from '../../../contexts/AuthorizationContext';
import { usePallet } from '../../../contexts/PalletContext';
import { useAlert } from '../../../contexts/AlertContext';
import ProposicaoNotificationFilterItens from "../../proposicao/_components/filtro/ProposicaoNotificationsFilterItens";
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import { IFilterData } from "../../../components/filter/ListFilter";
import useStyles from '../../../components/layout/Styles';
import SolidButton from '../../../components/SolidButton';
import EmptyError from '../../../components/EmptyError';

const NotificacaoContent = styled(Paper)`
    margin-bottom: 20px;
    .customScroll { 
        /* width */
        &::-webkit-scrollbar {
            width: 20px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #F2F2F2;
            border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #4B1196; 
            border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #4B1196; 
        }
    }
`;

const ButtonLimpar = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #4B1196;
    cursor: pointer;
    margin-left: 20px; 
`;

const TitleText = styled(Typography)`
    color: ${(props => props.pallet.textColorQuaternary)};
    font-weight: ${(props => props.pallet.general.weight_bold)};
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 width: 100%;
 text-align: center;
 background-color: #ffff;
 heigth: 100%;
`;

const ButtonLimparDisable = styled(Typography)`
    align-self: center; 
    font-size: 14px;
    color: #727272;
    margin-left: 20px; 
`;

interface Filtro {
    key: string;
    label: string;
    value: string;
};

const styleCreateModalFilter = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    borderRadius: '5px', // Borda arredondada
    border: 'none', // Remove a borda preta
    transition: 'all 0.5s ease' // Transição mais lenta
};

const NotificacaoPanel = (props: {
    title: string,
    subtitle?: string,
    filters: IFiltroNotificacaoProps[],
    onChange: (event: any) => void,
    id?: string,
    list?: INotificationsContextResultData[],
    reloadList?: (event?: number) => void;
    mudaDePagina?: () => void;
    filtro?: string
    removeNotificacao?: (id: number) => void;
    limparCheck?: boolean
}) => {
    const { pallet } = usePallet();
    const classes = useStyles();
    const divInfiniteScrollRef = useRef(null);
    const { NewAlert } = useAlert();
    const [openPopover, setOpenPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [limpar, setLimpar] = useState<boolean>(false);
    const [exp, setExp] = useState<boolean>(false);
    const { user, getConfig } = useAuthorization();
    const [arquivado, setArquivado] = useState<boolean>();
    const [arquivou, setArquivou] = useState<string>('')
    const [arrayIdsI, setArrayIdsI] = React.useState<Array<any>>([{ id: 0, status: false }]);
    const [arrayIdsII, setArrayIdsII] = React.useState<Array<number>>([]);
    const [checked, setChecked] = useState<boolean>(false);
    const [controlAccordion, setControlAccordion] = useState<boolean>(false);
    const [isFilter, setIsFilter] = useState<string>('Nenhum filtro selecionado');
    const [filtro, setFiltro] = useState<string>('TODOS');
    const [filters, setFilters] = React.useState<Array<any>>([]);
    const [filtrosNotificacao, setFiltrosNotificacao] = React.useState<Array<IFiltroNotificacaoProps>>([
        { key: 'modulo', label: 'TODOS', total: 0, active: true },
        { key: 'modulo', label: 'PROPOSIÇÃO', total: 0, active: false },
        { key: 'modulo', label: 'INTERLOCUTOR', total: 0, active: false },
        { key: 'modulo', label: 'AGENDA', total: 0, active: false },
        { key: 'modulo', label: 'CITAÇÕES', total: 0, active: false },
        { key: 'modulo', label: 'TWITTER', total: 0, active: false }
    ]);

    const [modulo, setModulo] = useState<any>({ key: 'modulo', label: 'todos', value: 'TODOS' });

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        handleUpdateListFilter();

        let filterJson = localStorage.getItem('filterSelectedJson');
        let filterValue = localStorage.getItem('filterSelectedValue');

        let parseFilter: any = []
        if (filterJson) {
            parseFilter = JSON.parse(filterJson);
            if ('filtro' in parseFilter) {
                parseFilter = parseFilter.filtro;
            };
        } else {
            let newFilter = currentFilter.filter(filtro => filtro.key !== 'modulo');
            newFilter.push({ key: 'modulo', label: 'todos', value: 'TODOS' })
            parseFilter = newFilter
        };

        parseFilter.push({ key: "intervaloDiasCriacao", value: 5 });
        let filterSelectec = filterValue !== null && filterValue !== "undefined" ? filterValue : filterSelectedValue;

        setFilterSelectedValue(filterSelectec);
        requestReturnListByFilter(parseFilter, []);
    }, []);

    const handleUpdateListFilter = async (id: number = undefined) => {
        await NotificationsContextService.Filtros().then(res => {
            if (res !== undefined) {
                const dadosFormatados = res.map(dado => {
                    return {
                        id: dado.id,
                        filtro: JSON.parse(dado.filtro) as Filtro[]
                    };
                });

                const dadosComLabel = dadosFormatados.map(dado => {
                    const label = dado.filtro.map(item => item.label).join(", ");
                    const teste = dado.filtro.find(item => item.key === "titulo");
                    return {
                        id: dado.id,
                        label: label,
                        filtro: dado.filtro,
                        titulo: teste !== undefined ? teste.label : label
                    };
                });

                setFilters(dadosComLabel);
                if (id) {
                    let itemFilter = dadosComLabel.find(item => item.id === id);
                    let itemTitulo = itemFilter.filtro.find(item => item.key === "titulo");

                    setFilterSelectedValue(itemTitulo !== undefined ? itemTitulo?.label : itemFilter.label);

                    localStorage.setItem('filterSelectedValue', itemTitulo !== undefined ? itemTitulo?.label : itemFilter.label);
                    localStorage.setItem('filterSelectedJson', JSON.stringify(itemFilter));
                };
            };
        });
    };

    React.useEffect(() => {
        if (props.limparCheck) {
            setLimpar(true);
            setArrayIdsI([]);
        }
    }, [props.limparCheck]);

    const [list, setList] = React.useState([]);

    useEffect(() => {
        if (list?.length > 0) {
            for (var i = 0; i < list?.length; i++) {
                if (!arrayIdsI.find(x => x.id === list[i].id)) {
                    arrayIdsI?.push({ id: list[i].id, status: false });
                };
            };
        };
    }, [list]);

    const handleArquivado = async () => {
        arrayIdsI.map((a) => {
            if (a.status === true) {
                arrayIdsII.push(a.id)
            };
        });

        for (var i = 0; i < arrayIdsII.length; i++) {
            await NotificationsContextService.Arquivar(arrayIdsII[i]).then((res) => { });
        };

        setArquivado(true);
        setArquivou("DELETE");
        handleLimpar();

        requestReturnListByFilter(currentFilter);

        NewAlert('success', 'Notificações selecionadas arquivadas com sucesso!');
    };

    const handleFalse = () => {
        setChecked(false);
    };

    const handleLimpar = () => {
        setLimpar(true);
        handleFalse();

        arrayIdsI.map((a) => {
            a.status = false;
        })
    };

    const handleArquivarTodas = async () => {
        NewAlert('success', 'Por favor, aguarde. Estamos arquivando suas notificações! Este processo pode demorar um pouco.')

        await NotificationsContextService.ArquivarTodasAreaTrabalho(filtro).then((res) => {
            requestReturnListByFilter(currentFilter);
        });

        setArquivado(true);
        setArquivou("DELETE");
    };

    const passarIds = (event: any, id: number) => {
        setLimpar(false);

        arrayIdsI.map((a) => {
            if (a.id === id && a.status === false) {
                a.status = true;
            } else {
                if (a.id === id && a.status === true) { a.status = false; };
            }
        });
    };

    const [page, setPage] = React.useState(1);
    useEffect(() => {
        if (page > 0 && list?.length && !list.slice(-1)[0].isLast) {
            const lastCount = list.length;
            let filterJson = localStorage.getItem('filterSelectedJson');
            let filterValue = localStorage.getItem('filterSelectedValue');

            let parseFilter: any = [{}];
            if (filterJson !== "undefined") {
                parseFilter = filterJson
            } else {
                parseFilter = currentOldFilter
            };

            filterJson = filterJson !== "undefined" ? parseFilter : currentFilter
            requestReturnListByFilter(parseFilter, filterJson);
            setFilterSelectedValue(filterValue !== null ? filterValue : filterSelectedValue);
        };
    }, [page]);

    const handleScroll = () => {
        const scrollTop = divInfiniteScrollRef.current.scrollTop;
        const scrollHeight = divInfiniteScrollRef.current.scrollHeight;
        const clientHeight = divInfiniteScrollRef.current.clientHeight;
        if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
            setPage((oldPage) => oldPage + 1);
        }
    };

    const navigate = useNavigate();

    const [filterSelectedValue, setFilterSelectedValue] = useState("Nenhum filtro selecionado");

    const requestReturnListByFilter = async (paramCurrentFilter: any = [], paramCurrentNewFilter: any = []) => {
        const isArray = (value: any) => Array.isArray(value);

        const isValidFilter = (item: any) => item && item.key && item.value;

        const useFilter = isArray(paramCurrentFilter) && isArray(paramCurrentNewFilter) && paramCurrentFilter.length > 0 && paramCurrentNewFilter.length > 0
            ? [
                ...paramCurrentFilter.filter(current => !paramCurrentNewFilter.some(newItem => newItem.key === current.key))
                    .filter(isValidFilter),
                ...paramCurrentNewFilter.filter(isValidFilter)
            ]
            : isArray(paramCurrentFilter) && paramCurrentFilter.length > 0
                ? paramCurrentFilter.filter(isValidFilter)
                : currentFilter;

        if (useFilter?.length) {
            let intervaloDiasCriacao = useFilter.filter(e => e.key === 'intervaloDiasCriacao')?.[0];
            if (!intervaloDiasCriacao) {
                intervaloDiasCriacao = { key: 'intervaloDiasCriacao' };
                useFilter.push(intervaloDiasCriacao);
            }
            intervaloDiasCriacao.value = 5;
        }

        await setCurrentFilter(useFilter);
        setControlAccordion(false);

        return await NotificationsContextService.GetAll(useFilter, 8 * page, page, '').then(result => {
            if (result) {
                setArrayIdsI([]);
                setArrayIdsII([]);
                setArquivou("");

                setFiltrosNotificacao(filtrosNotificacao.map(item => {
                    item.total = Number(result?.countByCategory[item.label] || 0);
                    return item;
                }));

                for (var i = 0; i < result.data?.length; i++) {
                    if (!arrayIdsI.find(x => x.id === result.data[i].id)) {
                        arrayIdsI?.push({ id: result.data[i].id, status: false });
                    };
                };

                if (result?.data?.length) result.data.slice(-1)[0].isLast = list?.length && list?.length === result?.data?.length;

                setArrayIdsI(arrayIdsI);
                setList(result.data);
                setExp(false);
            };
            return result?.data?.length;
        }).catch(() => {
            NewAlert("error", "Houve um problema na aplicação do filtro, tente novamente.");
        });
    };

    const [currentFilter, setCurrentFilter] = useState([]);
    const [currentOldFilter, setCurrentOldFilter] = useState(
        [{
            key: "modulo",
            label: undefined,
            value: "TODOS"
        }]
    );

    const handleChangeFilter = async (event) => {
        let id = event.target.name;
        id = id !== 'radio-buttons-group' ? Number(id) : id
        const value = event.target.value;
        let valueSelect = value;

        let filter = filtrosNotificacao.find(item => item.active === true);
        let filter_selected = filters.find(item => item.id === id);
        filter_selected = filter_selected !== undefined ? filter_selected.filtro : [{}];

        let filter_module = [{
            key: "modulo",
            label: filter.label,
            value: filter.label
        }];

        if (event.target.value !== "Nenhum filtro selecionado") {
            localStorage.setItem('filterSelectedJson', JSON.stringify(filter_selected));
        } else {
            localStorage.setItem('filterSelectedJson', JSON.stringify([{}]));
            filter_selected = [{}];
        };
        localStorage.setItem('filterSelectedValue', valueSelect);

        setIsFilter(event.target.value);
        setCurrentFilter(
            event.target.value !== "Nenhum filtro selecionado" ? filter_selected : [{}]
        );
        setCurrentOldFilter(filter_module);

        let itemTitulo = filter_selected.find(item => item.key === "titulo");
        setFilterSelectedValue(itemTitulo !== undefined ? itemTitulo?.label : event.target.value);

        requestReturnListByFilter(filter_module, filter_selected);
    };

    const handleOnChangeFilter = async (data: IFilterData[]) => {
        setCurrentFilter(data);
    };

    const [openModalCreateFilter, setOpenModalCreateFilter] = React.useState(false);
    const [openModalEditFilter, setOpenModalEditFilter] = React.useState(false);
    const [idFilterEdit, setIdFilterEdit] = React.useState("");

    const handleClose = () => {
        setOpenModalCreateFilter(false);
    };

    const handleOpen = () => {
        setCurrentFilter([]);
        setOpenModalCreateFilter(true);
        setOpenModalEditFilter(false);
    };

    const handleDelete = async (id) => {
        if (id) {
            setIdFilterEdit("");
            await NotificationsContextService.DeleteFiltro(id).then((res) => {
                NewAlert('success', 'Filtro deletado com sucesso!');
            }).catch(() => {
                NewAlert("error", "Não foi possivel deletar o filtro, tente novamente.");
            });

            handleUpdateListFilter();
        };
    };

    const handleEdit = (id, filter) => {
        if (id && filter) {
            setIdFilterEdit(id);
            setCurrentFilter(filter);
            setOpenModalCreateFilter(true);
            setOpenModalEditFilter(true);
        };
    };

    const handleSaveFilter = async () => {
        const checkEsferaIndex = currentFilter.findIndex(obj => obj.key === "esfera");

        if (currentFilter[checkEsferaIndex].value.length > 0) {
            if (currentFilter.length > 0 && checkEsferaIndex >= 0) {
                const esferaValueSelected = currentFilter[checkEsferaIndex].value;
                if (esferaValueSelected.includes("Federal")) {
                    const checkEstadoIndex = currentFilter.findIndex(obj => obj.key === "estado");
                    if (checkEstadoIndex >= 0) { currentFilter.splice(checkEstadoIndex, 1); };

                    const checkMunicipioIndex = currentFilter.findIndex(obj => obj.key === "municipio");
                    if (checkMunicipioIndex >= 0) { currentFilter.splice(checkMunicipioIndex, 1); };
                } else {
                    const checkCasaIndex = currentFilter.findIndex(obj => obj.key === "casa");
                    if (checkCasaIndex >= 0) { currentFilter.splice(checkCasaIndex, 1); };

                    if (checkEsferaIndex === 1) { currentFilter.reverse(); };
                };
            };

            if (openModalEditFilter) {
                await NotificationsContextService.UpdateFiltro(idFilterEdit, currentFilter).then((res) => {
                    NewAlert('success', 'Filtro alterado com sucesso!');
                    requestReturnListByFilter(currentOldFilter, currentFilter);
                    handleUpdateListFilter(res[0]);
                }).catch(() => {
                    NewAlert("error", "Não foi possivel alterar o filtro, tente novamente.");
                });
            } else {
                await NotificationsContextService.SalvarFiltro(currentFilter).then((res) => {
                    NewAlert('success', 'Filtro salvo com sucesso!');
                    requestReturnListByFilter(currentOldFilter, currentFilter);
                    handleUpdateListFilter(res[0]);
                }).catch(() => {
                    NewAlert("error", "Não foi possivel salvar o filtro, tente novamente.");
                });
            };

            handleUpdateListFilter();
            setOpenModalCreateFilter(false);
        };
    };

    const handleOnChange = (e) => {

            divInfiniteScrollRef.current.scrollTop = 0;
            setList([]);

            setFiltrosNotificacao(filtrosNotificacao.map(note => {
                if (note.label.toLowerCase() === e.target.title.toLowerCase()) {
                    note.active = true;
                    setFiltro(note.label);
                } else if (note.active) note.active = false;

                return note;
            }));

            let filterNotifications = localStorage.getItem('filterSelectedJson');
            let newFilter: any = currentOldFilter.filter(filtro => filtro.key !== 'modulo');
            if (filterNotifications !== "undefined" && filterNotifications !== null) {
                newFilter = JSON.parse(filterNotifications);

                if ('filtro' in newFilter) {
                    newFilter = newFilter.filtro
                }
            };

            newFilter.push({ key: 'modulo', label: e.target.key, value: e.target.title });
            setModulo({ key: 'modulo', label: e.target.key, value: e.target.title });

            setPage(1);
            setFiltro(e.target.title);

            setCurrentOldFilter(newFilter);

            filterNotifications = filterNotifications !== null && filterNotifications !== "undefined" ? JSON.parse(filterNotifications) : [{}];

            requestReturnListByFilter(newFilter, filterNotifications);
        
    };

    const openDetected = (isExpended) => {
        setExp(isExpended);
    };

    return (
        <NotificacaoContent className={classes.paper}
            id={props.id ? props.id : "notificacao-panel"}
            style={{
                padding: "unset",
            }}
        >
            <Grid item xs={12}
                style={{
                    padding: "10px 17px",
                    margin: "5px 10px",
                    cursor: "pointer",
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                container

            >
                <Grid>
                    <TitleText pallet={pallet} onClick={() => navigate('/central-notificacoes')}>
                        {props.title}
                        <span style={{ color: "#00000091", marginLeft: "5px", fontStyle: "italic", fontSize: "60%" }}>{props.subtitle}</span>
                    </TitleText>
                </Grid>
                {/* <Grid>
                    <IconButton color="primary" onClick={handleClickPopover}>
                        <HelpOutlineIcon fontSize="medium" style={{ color: '#858585' }} />
                    </IconButton>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ p: 2, maxWidth: 450 }}>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Lista de Notificação em Workspace
                            </Typography>
                            <Grid>
                                Exibe uma lista de notificações de novas proposições, suas movimentações e os processamentos que o Legisnote realiza em resposta a detecções relevantes às rotinas de interesse do usuário, como a Notificação de Citação, por exemplo.
                            </Grid>
                            <Grid style={{ marginTop: '20px', fontSize: '12px' }}>
                                <Grid style={{ display: 'flex', alignItems: 'flex-start', marginTop: '5px' }}>
                                    <div
                                        style={{
                                            width: 5,
                                            height: 5,
                                            backgroundColor: 'black',
                                            borderRadius: '50%',
                                            marginRight: 8,
                                            flexShrink: 0,
                                            marginTop: '5px'
                                        }}
                                    />
                                    <span>Prazo máximo de permanëncia da notificação na lista é de 5 dias.</span>
                                </Grid>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div
                                        style={{
                                            width: 5,
                                            height: 5,
                                            backgroundColor: 'black',
                                            borderRadius: '50%',
                                            marginRight: 8,
                                            flexShrink: 0,
                                            marginTop: '5px'
                                        }}
                                    />
                                    <span style={{ lineHeight: '1.5' }}>
                                        O usuário que seguir uma nova proposição, terá a notificação retirada da lista e arquivada.
                                    </span>
                                </div>
                            </Grid>

                            <Link href="https://linkexterno.com" target="_blank" underline="hover" style={{ display: 'block', marginTop: '20px' }}>
                                Não entendi, preciso de ajuda.
                            </Link>
                        </Box>
                    </Popover>
                </Grid> */}
            </Grid>

            <Grid container xs={12}
                style={{
                    backgroundColor: "#F9F9F9",
                    padding: "10px 17px",
                }}
            >
                <NotificacoesList filtros={filtrosNotificacao} onChange={handleOnChange} />
            </Grid>

            {getConfig()?.habNotFiltroMult ?
                <div>
                    <Modal
                        open={openModalCreateFilter}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleCreateModalFilter}>
                            <ProposicaoNotificationFilterItens
                                filter={currentFilter}
                                onChangeFilter={handleOnChangeFilter}
                                idPermissao={user.id_permissao}
                            />

                            <br></br>

                            <Grid container justifyContent="end" style={{ padding: "15px 0px 15px 0px" }}>
                                <Grid item style={{ marginRight: `${isMobile ? "5px" : "20px"}` }}>
                                    <SolidButton
                                        onClick={() => handleSaveFilter()}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                    />
                                </Grid>

                                <Grid item>
                                    <SolidButton
                                        onClick={() => handleClose()}
                                        color={`${pallet.textColorSecondary}`}
                                        backgroundColor={`${pallet.backgroundColorSecondary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"FECHAR"}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div> : null
            }

            <Grid container style={{
                padding: "0px 0px 5px 18px",
                backgroundColor: "#f9f9f9"
            }}>
                {getConfig()?.habNotFiltroMult ?
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{
                        width: "100%",
                        fontFamily: pallet.fontFamily.general,
                        position: "relative" // adiciona isso para o pai ter posição relativa
                    }}>
                        <div style={{
                            position: "absolute",
                            zIndex: 1000,
                            width: "100%",
                            paddingTop: '5px'
                        }}> {/* Adicione este wrapper */}
                            <Accordion
                                expanded={exp}
                                sx={{ boxShadow: 'none' }}
                                onChange={(event, isExpended) => openDetected(isExpended)}
                                style={{
                                    width: "100%",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra
                                    backgroundColor: "white", // Adiciona uma cor de fundo
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <span style={{ fontWeight: pallet.general.weight_600 }}>Filtro: </span>
                                    <span style={{ marginLeft: '5px' }}>{filterSelectedValue}</span>
                                </AccordionSummary>
                                <AccordionDetails style={{
                                    width: "100%"
                                }}>
                                    <FormControl style={{
                                        width: "100%"
                                    }}>
                                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={filterSelectedValue}
                                            onChange={handleChangeFilter}
                                        >
                                            <Grid container style={{
                                                backgroundColor: "#f9f9f9",
                                                padding: "8px",
                                                marginBottom: "5px",
                                                width: "100%",
                                                borderRadius: "5px"
                                            }}>
                                                <FormControlLabel value="Nenhum filtro selecionado" control={<Radio />} label="Nenhum" id="nenhum" />
                                            </Grid>

                                            {filters?.length > 0 ?
                                                filters?.map((item, index) => {
                                                    return (
                                                        <Grid container key={index} style={{
                                                            backgroundColor: "#f9f9f9",
                                                            padding: "8px",
                                                            marginBottom: "5px",
                                                            width: "100%",
                                                            borderRadius: "5px"
                                                        }}>
                                                            <Grid>
                                                                <FormControlLabel
                                                                    value={item.titulo}
                                                                    control={<Radio />}
                                                                    label={
                                                                        <Grid style={{ wordWrap: "break-word" }}>{item.titulo}</Grid>
                                                                    }
                                                                    id={item.id}
                                                                    name={item.id}
                                                                />
                                                            </Grid>

                                                            <IconButton aria-label="edit" onClick={() => handleEdit(item.id, item.filtro)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" onClick={() => handleDelete(item.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )
                                                }) : null
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button onClick={() => handleOpen()} style={{
                                        alignSelf: "center",
                                        fontSize: "14px",
                                        color: "#4B1196",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}>CRIAR</Button>
                                </AccordionActions>
                            </Accordion>
                        </div>
                    </Grid>

                    :

                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{
                        width: "100%",
                        fontFamily: pallet.fontFamily.general,
                        position: "relative"
                    }} />
                }

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    {list?.length > 0 ?
                        <Grid style={{
                            alignItems: 'right',
                            display: 'flex',
                            margin: '10px 15px 10px 0px',
                            justifyContent: 'right',
                            marginTop:isMobile?  '60px' : null

                        }}>
                            <ButtonLimpar onClick={handleLimpar} style={{ marginRight: '10px' }}>LIMPAR</ButtonLimpar>

                            <ButtonDialogConfirmation
                                size={'large'}
                                styles={{
                                    "&:hover": {
                                        backgroundColor: '#4B1196',
                                    },
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    color: `${pallet.textColorPrimary}`,
                                    fontSize: `${pallet.general.size12}`,
                                    background: '#4B1196',
                                    letterSpacing: "1px",
                                    border: `${"1px solid lightgrey"}`,
                                    borderRadius: "5px",
                                    fontWeight: pallet.general.weight_600,
                                    padding: "10px",
                                    marginLeft: '10px'

                                }}
                                title={"CONFIRMAÇÃO"}
                                // lastPath={"/central-notificacoes"}
                                confirmationEvent={() => user.id_permissao !== 3 ? handleArquivado() : NewAlert(
                                    "error",
                                    "Não é possível realizar está ação com este perfil.")}
                                title_button={"ARQUIVAR SELECIONADAS"}
                                confirmation_button={"Arquivar"}
                                content={"DESEJA REALMENTE ARQUIVAR AS NOTIFICAÇÕES SELECIONADAS?"}
                                message={"Esta ação não poderá ser desfeita."}
                            />

                            <ButtonDialogConfirmation
                                size={'large'}
                                styles={{
                                    "&:hover": {
                                        backgroundColor: '#4B1196',
                                    },
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    color: `${pallet.textColorPrimary}`,
                                    fontSize: `${pallet.general.size12}`,
                                    background: '#4B1196',
                                    letterSpacing: "1px",
                                    border: `${"1px solid lightgrey"}`,
                                    borderRadius: "5px",
                                    fontWeight: pallet.general.weight_600,
                                    padding: "10px",
                                    marginLeft: '10px'

                                }}
                                title={"CONFIRMAÇÃO"}
                                // lastPath={"/central-notificacoes"}
                                confirmationEvent={() => user.id_permissao !== 3 ? handleArquivarTodas() : NewAlert(
                                    "error",
                                    "Não é possível realizar está ação com este perfil.")}
                                title_button={"ARQUIVAR TODAS"}
                                confirmation_button={"Arquivar"}
                                content={"DESEJA REALMENTE ARQUIVAR TODAS AS NOTIFICAÇÕES?"}
                                message={"Esta ação não poderá ser desfeita."}
                            />
                        </Grid>

                        :

                        <Grid style={{
                            alignItems: 'right',
                            display: 'flex',
                            margin: '10px 15px 10px 0px',
                            justifyContent: 'right'
                        }}>
                            <ButtonLimparDisable style={{ marginRight: '10px' }}>Limpar</ButtonLimparDisable>

                            <Button
                                size="large"
                                sx={{
                                    backgroundColor: '#0048ba',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.general.size12}`,
                                    background: '#FFFFFF',
                                    letterSpacing: "1px",
                                    border: `${"1px solid lightgrey"}`,
                                    borderRadius: "5px",
                                    fontWeight: pallet.general.weight_600,
                                    padding: "10px",
                                    marginLeft: '10px',
                                    color: '#FF69B4'
                                }}
                                disabled
                            >
                                ARQUIVAR SELECIONADAS
                            </Button>

                            <Button
                                size="large"
                                sx={{
                                    backgroundColor: '#e32636',
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    color: `${pallet.textColorSecondary}`,
                                    fontSize: `${pallet.general.size12}`,
                                    background: '#FFFFFF',
                                    letterSpacing: "1px",
                                    border: `${"1px solid lightgrey"}`,
                                    borderRadius: "5px",
                                    fontWeight: pallet.general.weight_600,
                                    padding: "10px",
                                    marginLeft: '10px'

                                }}
                                disabled
                            >
                                <div style={{ fontSize: 0 }}>@</div>ARQUIVAR TODAS
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid className="customScroll" item xs={12}
                style={{
                    // padding: "10px 17px 17px",
                    minHeight: list?.length > 0 ? "900px" : "10px",
                    maxHeight: "1500px",
                    overflow: "auto",
                    margin: "10px 17px 17px",
                }}
                ref={divInfiniteScrollRef} onScroll={handleScroll}
            >
                {list?.length > 0 ?
                    list?.map((item, index) => {
                        return (
                            <NotificacaoCard
                                notification={item}
                                limpar={limpar}
                                onChange={handleLimpar}
                                checked={checked}
                                idsFlow={passarIds}
                                arquivado={arquivado}
                                status={'A'}
                                index={index}
                                central={false}
                                reloadList={requestReturnListByFilter}
                                idPermissao={user.id_permissao}
                                filtro={props.filtro}
                                removeNotificacao={() => { }}
                            />
                        )
                    })

                    :

                    <>
                        <PaperContent pallet={pallet} style={{ marginTop: '40px' }}>
                            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
                        </PaperContent>
                    </>
                }
                <div style={{ border: "1px solid white", height: "10px", width: "100%" }} />
            </Grid>
        </NotificacaoContent >
    )
};

export default NotificacaoPanel;
